const LiveCategories = [
	{
		src: '/categories/live/blackjack.webp',
		alt: 'Blackjack Live88',
	},
	{
		src: '/categories/live/baccarat.webp',
		alt: 'Baccarat Evolution',
	},
	{
		src: '/categories/live/teenpatti.webp',
		alt: 'Teenpatti Live88',
	},
	{
		src: '/categories/live/poker.webp',
		alt: 'Poker Evolution',
	},
	{
		src: '/categories/live/roulette.webp',
		alt: 'Roulette Live88',
	},
	{
		src: '/categories/live/entertainment.webp',
		alt: 'Entertainment Evolution',
	},
];

export default LiveCategories;
