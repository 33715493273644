import * as React from 'react';

const SvgTennis = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
		<path
			fill="#707290"
			d="m28.48 18.967 2.948-2.947-2.948-2.948-2.947 2.948zm.91.91 2.038 2.037a10.7 10.7 0 0 0 2.126-3.77l-1.215-1.215zm4.636-6.454c-.196-1.588-.764-3.059-1.697-4.2l-2.939 2.94 2.948 2.947zm.066 1.75-.845.846.673.672q.139-.76.172-1.517m-6.52 4.703-2.948-2.947-2.948 2.947 2.948 2.948zm.908.91-2.947 2.947 1.25 1.25a10.9 10.9 0 0 0 3.737-2.16zm-6.503 4.686 1.738-1.738-2.948-2.948-2.94 2.941c1.148.953 2.594 1.535 4.15 1.745m1.737.081c.54-.02 1.088-.084 1.631-.189l-.721-.721zm-2.039-13.39 2.948 2.947 2.947-2.948-2.947-2.947zm-.909-.91 2.948-2.947-1.215-1.215a10.7 10.7 0 0 0-3.77 2.126zm6.454-4.635-1.687 1.688 2.947 2.947 2.939-2.938c-1.14-.934-2.61-1.501-4.199-1.697m-1.751-.067q-.758.033-1.518.172l.673.672zm-4.703 6.52L17.82 16.02l2.947 2.947 2.948-2.947zm-.909-.909-2.038-2.038a10.9 10.9 0 0 0-2.16 3.737l1.25 1.25 2.948-2.948m-4.685 6.505c.21 1.556.793 3.001 1.746 4.15l2.94-2.94-2.947-2.948zm-.081-1.737.91-.91-.721-.721a10.6 10.6 0 0 0-.19 1.63m-1.556 5.283c-1.743 4.75-3.148 7.776-4.279 9.164l-.087.098-2.727 2.727 2.775-2.773c.736-.664 2.045-1.385 3.897-2.213a68 68 0 0 1 2.644-1.107c.857-.34 1.75-.678 2.656-1.01a9.9 9.9 0 0 1-2.88-1.99 9.9 9.9 0 0 1-1.997-2.897zm-1.023-4.832c-.013-3.384 1.305-6.92 3.93-9.545 4.832-4.831 13.048-5.135 17.273-.909 4.225 4.227 3.923 12.443-.909 17.274-2.625 2.624-6.16 3.943-9.544 3.93l-.058.02a137.698 137.698 0 0 0-2.03.69 121 121 0 0 0-4.471 1.659c-.93.367-1.781.723-2.54 1.063-1.614.72-2.752 1.348-3.176 1.729L7.35 36.928a1.286 1.286 0 0 1-1.818 0L3.715 35.11a1.286 1.286 0 0 1 0-1.818L7.3 29.705c1.152-1.456 2.923-5.605 5.197-12.282zM6.19 10.214A5.57 5.57 0 0 1 7.676 7.55a5.57 5.57 0 0 1 2.51-1.448 3.22 3.22 0 0 0-2.209-2.066A5.57 5.57 0 0 1 6.55 6.473a5.56 5.56 0 0 1-2.526 1.451 3.22 3.22 0 0 0 2.166 2.29m1.295.126a3.22 3.22 0 0 0 2.863-2.946A4.3 4.3 0 0 0 8.585 8.46a4.3 4.3 0 0 0-1.1 1.88m-3.51-3.741A4.3 4.3 0 0 0 5.64 5.564a4.3 4.3 0 0 0 1.011-1.599A3.22 3.22 0 0 0 3.975 6.6m3.168 5.043a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9"
		/>
	</svg>
);

export default SvgTennis;
