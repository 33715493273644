import * as React from 'react';

const SvgLive = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={41} height={40} fill="none" {...props}>
		<path
			fill="#707290"
			d="M20.322 13.467v4.434h1.994v-4.434a2.58 2.58 0 0 0 1.575-2.37 2.575 2.575 0 0 0-2.572-2.573 2.575 2.575 0 0 0-2.572 2.572c0 1.065.65 1.98 1.575 2.371M5.857 19.895a2 2 0 0 0-2 2V35a2 2 0 0 0 2 2h30a2 2 0 0 0 2-2V21.895a2 2 0 0 0-2-2zm7.717 12.935H9.199v-8.787h1.994v6.793h2.38zm4-.058H15.58v-8.793h1.994zm5.931-.14h-1.73l-2.992-8.666h2.108l1.729 5.005 1.661-5.005h2.149zm9.011-6.628h-2.838v1.374h2.629v1.994h-2.63v1.375h2.839v1.994h-4.833V24.01h4.833zM24.567 15.207l1.41 1.41.705-.705a6.82 6.82 0 0 0 0-9.632l-.705-.705-1.41 1.41.705.705a4.823 4.823 0 0 1 0 6.812zM15.956 15.913l.705.704 1.41-1.41-.705-.705a4.79 4.79 0 0 1-1.411-3.406c0-1.286.5-2.496 1.41-3.406l.706-.705-1.41-1.41-.705.705a6.77 6.77 0 0 0-1.995 4.816c0 1.82.708 3.53 1.995 4.817"
		/>
		<path
			fill="#707290"
			d="m28.527 17.077-.705.705 1.41 1.41.705-.705c4.075-4.075 4.075-10.706 0-14.782L29.232 3l-1.41 1.41.705.705c3.298 3.298 3.298 8.664 0 11.962M13.405 19.192l1.41-1.41-.705-.705a8.4 8.4 0 0 1-2.477-5.98c0-2.26.88-4.384 2.477-5.982l.705-.705L13.405 3l-.705.705a10.38 10.38 0 0 0-3.061 7.391c0 2.792 1.087 5.417 3.061 7.391z"
		/>
	</svg>
);

export default SvgLive;
