const GameProviders = [
	{
		slug: 'relaxgaming',
		imageSrc: 'relaxgaming.webp',
	},
	{
		slug: 'playngo',
		imageSrc: 'playngo.webp',
	},
	{
		slug: 'pushgaming',
		imageSrc: 'pushgaming.webp',
	},
	{
		slug: 'bigtimegaming',
		imageSrc: 'bigtimegaming.webp',
	},
	{
		slug: 'thunderkick',
		imageSrc: 'thunderkick.webp',
	},
	{
		slug: 'microgaming',
		imageSrc: 'microgaming.webp',
	},
	{
		slug: 'apparat',
		imageSrc: 'apparat.webp',
	},
	{
		slug: 'avatarux',
		imageSrc: 'avatarux.webp',
	},
	{
		slug: 'bgaming',
		imageSrc: 'bgaming.webp',
	},
	{
		slug: 'bombay_live',
		imageSrc: 'bombay_live.webp',
	},
	{
		slug: 'caleta',
		imageSrc: 'caleta.webp',
	},
	{
		slug: 'darwingaming',
		imageSrc: 'darwingaming.webp',
	},
	{
		slug: 'evolutiongaming',
		imageSrc: 'evolutiongaming.webp',
	},
	{
		slug: 'evoplay',
		imageSrc: 'evoplay.webp',
	},
	{
		slug: 'gameart',
		imageSrc: 'gameart.webp',
	},
	{
		slug: 'golden_rock',
		imageSrc: 'golden_rock.webp',
	},
	{
		slug: 'hacksaw',
		imageSrc: 'hacksaw.webp',
	},
	{
		slug: 'kalamba_games',
		imageSrc: 'kalamba_games.webp',
	},
	{
		slug: 'netent',
		imageSrc: 'netent.webp',
	},
	{
		slug: 'nolimit_city',
		imageSrc: 'nolimit_city.webp',
	},
	{
		slug: 'onetouch',
		imageSrc: 'onetouch.webp',
	},
	{
		slug: 'pg',
		imageSrc: 'pg.webp',
	},
	{
		slug: 'playson',
		imageSrc: 'playson.webp',
	},
	{
		slug: 'pracmatic_play',
		imageSrc: 'pracmatic_play.webp',
	},
	{
		slug: 'redtiger',
		imageSrc: 'redtiger.webp',
	},
	{
		slug: 'relax',
		imageSrc: 'relax.webp',
	},
	{
		slug: 'rogue',
		imageSrc: 'rogue.webp',
	},
	{
		slug: 'spadegaming',
		imageSrc: 'spadegaming.webp',
	},
	{
		slug: 'spribe',
		imageSrc: 'spribe.webp',
	},
	{
		slug: 'turbo_games',
		imageSrc: 'turbo_games.webp',
	},
];

export default GameProviders;
