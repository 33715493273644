import * as React from 'react';

const SvgHockey = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={41} height={40} fill="none" {...props}>
		<path
			fill="#707290"
			fillRule="evenodd"
			d="M4.28 11.25h9.768l.818 2.503a1 1 0 0 1 .025.085 2.08 2.08 0 0 0 2.022 1.579h11.563c1.658 0 3.247.658 4.42 1.83a6.25 6.25 0 0 1 1.83 4.42v3.876a6.2 6.2 0 0 0-1.25-.126h-10a6.25 6.25 0 0 0-4.42 1.83 6.25 6.25 0 0 0-1.83 4.42v1.248a9.58 9.58 0 0 1-9.1-7.13zm8.95-2.5H3.615L2.268 3.653c-.1-.375-.019-.775.218-1.081.237-.309.602-.489.99-.489h6.667c.54 0 1.02.349 1.188.862zm5.663 24.583v-1.666c0-1.215.483-2.382 1.342-3.242a4.6 4.6 0 0 1 3.241-1.342h10c1.215 0 2.382.484 3.242 1.342a4.6 4.6 0 0 1 1.341 3.242v1.666a4.6 4.6 0 0 1-1.341 3.242 4.6 4.6 0 0 1-3.242 1.342h-10a4.6 4.6 0 0 1-3.241-1.342 4.6 4.6 0 0 1-1.342-3.242"
			clipRule="evenodd"
		/>
	</svg>
);

export default SvgHockey;
