import BasketballIcon from '@/lib/icons/categories/sports/Basketball';
import EsportsIcon from '@/lib/icons/categories/sports/Esports';
import FootballIcon from '@/lib/icons/categories/sports/Football';
import HockeyIcon from '@/lib/icons/categories/sports/Hockey';
import HorseRacingIcon from '@/lib/icons/categories/sports/HorseRacing';
import LiveIcon from '@/lib/icons/categories/sports/Live';
import TennisIcon from '@/lib/icons/categories/sports/Tennis';

const sportCategories = [
	{
		icon: <LiveIcon />,
		name: 'Live',
	},
	{
		icon: <FootballIcon />,
		name: 'Football',
	},
	{
		icon: <HorseRacingIcon />,
		name: 'Horse Racing',
	},
	{
		icon: <TennisIcon />,
		name: 'Tennis',
	},
	{
		icon: <EsportsIcon />,
		name: 'E-sports',
	},
	{
		icon: <BasketballIcon />,
		name: 'Basketball',
	},
	{
		icon: <HockeyIcon />,
		name: 'Ice Hockey',
	},
];

export default sportCategories;
