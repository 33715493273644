import * as React from 'react';

type SportCategoryCardProps = {
	children: React.ReactNode;
};

type SportCategoryBannerProps = {
	icon: React.ReactNode;
	onClick?: () => void;
} & SportCategoryCardProps;

interface SportCategoryCardNamespace {
	Banner: React.FC<SportCategoryBannerProps>;
}

const SportCategoryCard: React.FC<SportCategoryCardProps> & SportCategoryCardNamespace = ({ children }) => {
	return <>{children}</>;
};

SportCategoryCard.Banner = ({ icon, children, onClick }: SportCategoryBannerProps) => {
	return (
		<button
			className="flex h-[100px] w-[157.71px] flex-none flex-col items-center justify-evenly rounded-xl border border-border bg-[#171B24]"
			onClick={onClick}
		>
			<div>{icon}</div>
			<div className="font-semibold text-brandblue-50">{children}</div>
		</button>
	);
};

export default SportCategoryCard;
