import * as React from 'react';

const SvgHorseRacing = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={41} height={40} fill="none" {...props}>
		<path
			fill="#707290"
			fillRule="evenodd"
			d="M9.153 25.142c1.28.493 3.178-.614 6.086-4.326 1.1.431 1.963 1.388 2.34 3.44.713 3.906-.42 6.406-1.998 9.842-.293.637-.586 1.268-.87 1.902h22.486l-.069-.059c1.535-11.515-8.846-17.17-1.261-13.617-3.259-8.37-13.303-11.093-6.216-10.224-3.646-3.536-8.244-5.49-14.78-4.255-1.39-2.151-4.712-6.738-3.693-1.244L9.512 5.025l-.091 5.543c-1.98 1.603-3.062 6.94-4.595 10.412-1.311 1.38-1.815 3.029-1.397 5.594 2.042 2.79 5.264 2.098 5.724-1.432"
			clipRule="evenodd"
		/>
	</svg>
);

export default SvgHorseRacing;
