import * as React from 'react';

const SvgBasketball = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={41} height={40} fill="none" {...props}>
		<path
			fill="#707290"
			d="M20.428 38c9.911 0 18-8.09 18-18s-8.09-18-18-18-18 8.09-18 18 8.09 18 18 18m8.259-4.429c-1.1-1.293-1.748-2.703-1.865-4.491 2.912-.45 5.617-1.206 7.941-2.226a16 16 0 0 1-6.076 6.717M36.319 20c0 1.37-.174 2.7-.502 3.968-2.395 1.377-5.456 2.388-8.857 2.956.27-1.406.884-2.806 1.835-4.065 2.056-2.72 3.235-6.48 3.235-10.312 0-1.286-.132-2.563-.388-3.795A15.84 15.84 0 0 1 36.319 20M21.483 4.145a15.8 15.8 0 0 1 7.265 2.322c.767 1.856 1.172 3.95 1.172 6.08 0 3.383-1.023 6.678-2.808 9.04-1.304 1.727-2.086 3.683-2.332 5.635-1.077.116-2.179.189-3.297.217zm0 25.404a43 43 0 0 0 3.244-.202c.157 2.018.863 3.698 2.05 5.219a15.8 15.8 0 0 1-5.294 1.289zm-2.11 6.306a15.8 15.8 0 0 1-5.293-1.29c1.184-1.516 1.892-3.195 2.049-5.218q1.596.161 3.244.202zM12.109 6.467a15.8 15.8 0 0 1 7.265-2.322V27.44a40 40 0 0 1-3.297-.217c-.245-1.952-1.027-3.908-2.331-5.634-1.785-2.363-2.809-5.658-2.809-9.041 0-2.13.405-4.224 1.172-6.08M4.538 20c0-4.39 1.789-8.37 4.677-11.248a18.7 18.7 0 0 0-.388 3.795c0 3.832 1.179 7.591 3.234 10.312.951 1.259 1.566 2.659 1.835 4.065-3.4-.568-6.461-1.579-8.857-2.956A15.9 15.9 0 0 1 4.538 20m9.496 9.08c-.116 1.784-.762 3.195-1.865 4.491a16 16 0 0 1-6.076-6.717c2.324 1.02 5.03 1.776 7.941 2.226"
		/>
	</svg>
);

export default SvgBasketball;
