import * as React from 'react';

const SvgSports = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
		<path
			fill="#9092B2"
			d="M12 2.4c-5.293 0-9.6 4.307-9.6 9.6s4.307 9.6 9.6 9.6 9.6-4.307 9.6-9.6-4.307-9.6-9.6-9.6m.61 3.119 2.468-1.327a8.44 8.44 0 0 1 3.467 2.556l-.588 2.714-1.883.923L12.61 7.86zM8.942 4.184l2.469 1.332v2.345l-3.462 2.524-1.893-.924-.588-2.731a8.4 8.4 0 0 1 3.474-2.546m-3.866 12.56a8.35 8.35 0 0 1-1.434-3.928l2.032-2.207 1.852.906 1.396 3.964-1.18 1.413zm9.064 3.37c-.686.18-1.4.286-2.14.286a8.4 8.4 0 0 1-2.567-.404l-.755-2.35L9.882 16.2h4.24l1.183 1.4zm2.1-3.262-1.16-1.374 1.415-3.963 1.845-.903 2.02 2.205a8.3 8.3 0 0 1-1.135 3.445z"
		/>
	</svg>
);

export default SvgSports;
