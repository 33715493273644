import * as React from 'react';

const SvgEsports = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={41} height={40} fill="none" {...props}>
		<path
			fill="#707290"
			fillRule="evenodd"
			d="m38.365 17.207-3.388-9.042c-1.078-2.978-4.089-5.065-7.255-5.03H13.708C10.542 3.1 7.53 5.187 6.453 8.165l-3.388 9.042c-1.669 4.426 2.928 8.726 7.228 6.75a5.32 5.32 0 0 0 2.795-2.995l.4-1.067h14.454l.4 1.067c.737 2.06 2.833 3.497 5.008 3.477 3.667.05 6.354-3.807 5.015-7.232M26.348 9.825V7.713h2.113v2.106h2.112v2.113h-2.112V9.825zm2.113 2.113v2.112h-2.113v-2.112zm-2.12-2.119v2.113H24.23V9.819zm-3.443 3.597v2.113h-4.296v-2.113zm-11.83-1.267v-2.113h1.69v-1.69h2.112v1.69h1.69v2.113h-1.69v1.69h-2.112v-1.69zm6.616 9.957-2.42 9.126h10.903l-2.421-9.126zm5.32 6.45h-4.578v-2.113h4.578zm-10.81 8.309v-3.52h17.041v3.52z"
			clipRule="evenodd"
		/>
	</svg>
);

export default SvgEsports;
