import * as React from 'react';

const SvgGameProviders = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
		<path
			fill="#9092B2"
			d="M20.573 19.73H3.429c-.565 0-1.023.458-1.023 1.023v1.224c0 .565.458 1.023 1.023 1.023h17.144c.565 0 1.023-.458 1.023-1.023v-1.224c0-.565-.458-1.023-1.023-1.023M7.717 17.096h8.566a2.633 2.633 0 0 1 2.635 2.634H5.082a2.634 2.634 0 0 1 2.635-2.634M16.048 5.048a4.05 4.05 0 0 1-2.826 3.862 4.1 4.1 0 0 1-2.444 0 4.049 4.049 0 1 1 5.27-3.862"
		/>
		<path
			fill="#9092B2"
			d="M9.725 5.476a.43.43 0 0 1-.428-.428A2.707 2.707 0 0 1 12 2.344a.428.428 0 0 1 0 .856 1.85 1.85 0 0 0-1.849 1.848.43.43 0 0 1-.427.428M13.222 8.91v8.187h-2.445V8.91a4.1 4.1 0 0 0 2.445 0"
		/>
	</svg>
);

export default SvgGameProviders;
