import * as React from 'react';

const SvgLiveCasino = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
		<path
			fill="#9092B2"
			d="m21.566 9.489-2.23 8.818c-.065.256-.21.482-.413.643s-.453.25-.71.25H5.791c-.257 0-.507-.088-.71-.25a1.2 1.2 0 0 1-.413-.643L2.438 9.49a1.2 1.2 0 0 1 .011-.646c.062-.21.179-.4.338-.545a1.131 1.131 0 0 1 1.19-.224l3.695 1.461a.18.18 0 0 0 .215-.06l3.2-4.214c.108-.143.247-.26.405-.34a1.13 1.13 0 0 1 1.425.34l3.2 4.214q.038.05.097.067.06.015.117-.007l3.695-1.461a1.14 1.14 0 0 1 1.184.231c.159.146.275.333.338.541s.069.43.018.643"
		/>
	</svg>
);

export default SvgLiveCasino;
