import * as React from 'react';
import { useTranslation } from 'react-i18next';

import BannerCarousel from '@/lib/components/BannerCarousel';
import GameCard from '@/lib/components/GameCard';
import GameCategory from '@/lib/components/GameCategory';
import GameProviderCard from '@/lib/components/GameProviderCard';
import LiveCategoryCard from '@/lib/components/LiveCategoryCard';
import SportCategoryCard from '@/lib/components/SportCategoryCard';
import GameProvidersIcon from '@/lib/icons/categories/GameProviders';
import LiveCasinoIcon from '@/lib/icons/categories/LiveCasino';
import SportsIcon from '@/lib/icons/categories/Sports';
import SlotsIcon from '@/lib/icons/categories/games/Slots';
import GamesSearchInput from '@/src/components/GamesSearchInput';
import useLayoutContext from '@/src/hooks/useLayoutContext';
import usePageContext from '@/src/hooks/usePageContext';
import gameProviders from '@/src/utils/GameProviderCategories';
import liveCategories from '@/src/utils/LiveCategories';
import sportCategories from '@/src/utils/SportsCategories';
import { REFER_A_FRIEND_PROMOTION_ROUTE } from '@/src/utils/routes';

type LiveCategory = {
	src: string;
	alt: string;
	game?: Game;
};

type Game = {
	url_thumb: string;
	name: string;
	title: string;
	provider: string;
};

type SportCategory = {
	name: string;
	icon: React.ReactNode;
};

type GameCategory = {
	name: string;
	games: Game[];
};

export const Page = () => {
	const { t } = useTranslation();

	const { geoLobby } = usePageContext();
	const { navigateToGameProvider, navigateToGame, navigateToSportsbook, navigateToWhalesChoiceBanner, navigateTo } =
		useLayoutContext();

	const onGameProviderClicked = (gameProviderSlug: string) => {
		navigateToGameProvider(gameProviderSlug);
	};

	const onGameCardClicked = (game) => {
		navigateToGame(game?.game_provider?.slug, game?.slug);
	};

	const onLiveCategoryCardClicked = () => {
		navigateToSportsbook();
	};

	const onSportsCategoryCardClicked = () => {
		navigateToSportsbook();
	};

	const onRafBannerClicked = () => {
		navigateTo(`/promotions/${REFER_A_FRIEND_PROMOTION_ROUTE}`);
	};

	const onWhalesChoiceBannerClicked = () => {
		navigateToWhalesChoiceBanner();
	};

	const onBettingBannerClicked = () => {
		navigateToSportsbook();
	};

	return (
		<>
			<BannerCarousel>
				<BannerCarousel.Card
					alt="@Whale's Choice"
					src="./promotions/WhalesChoice.webp"
					onClick={onWhalesChoiceBannerClicked}
				/>
				<BannerCarousel.Card alt="Refer a Friend" src="./promotions/RAF.webp" onClick={onRafBannerClicked} />
				<BannerCarousel.Card alt="Betting" src="./promotions/Betting.webp" onClick={onBettingBannerClicked} />
			</BannerCarousel>

			<GamesSearchInput />

			{geoLobby?.Lobby.game_categories?.map((gameCategory: GameCategory, categoryIndex: number) => (
				<GameCategory key={`game-category-${gameCategory.name}-${categoryIndex}`}>
					<GameCategory.Title icon={<SlotsIcon />}>{gameCategory.name}</GameCategory.Title>
					<GameCategory.Cards>
						{gameCategory.games.map((game: Game, gameIndex: number) => (
							<GameCard
								key={`game-category-${gameCategory.name}-${categoryIndex}-game-${game.name}-${gameIndex}`}
								onClick={() => onGameCardClicked(game)}
							>
								<GameCard.Banner>
									<img className="h-28 w-[150px] cursor-pointer rounded-xl" src={game.url_thumb} alt={game.name} />
								</GameCard.Banner>
								<GameCard.Title>{game.name}</GameCard.Title>
								<GameCard.Provider>{game.game_provider?.name}</GameCard.Provider>
							</GameCard>
						))}
					</GameCategory.Cards>
				</GameCategory>
			))}

			<GameCategory>
				<GameCategory.Title icon={<GameProvidersIcon />}>{t('providersSection_title')}</GameCategory.Title>
				<GameCategory.InlineCardsWrapper>
					{gameProviders.map((provider, index) => (
						<GameProviderCard key={`game-provider-category-${index}`}>
							<GameProviderCard.Banner>
								<img
									className="h-20 cursor-pointer rounded-xl object-contain"
									src={`/providers/${provider.imageSrc}`}
									alt={provider.slug}
									onClick={() => onGameProviderClicked(`${provider.slug}`)}
								/>
							</GameProviderCard.Banner>
						</GameProviderCard>
					))}
				</GameCategory.InlineCardsWrapper>
			</GameCategory>

			<GameCategory>
				<GameCategory.Title icon={<LiveCasinoIcon />}>{t('global_liveCasino')}</GameCategory.Title>
				<GameCategory.InlineCardsWrapper>
					{liveCategories.map((liveCategory: LiveCategory, categoryIndex: number) => (
						<LiveCategoryCard key={`live-category-${liveCategory.alt}-${categoryIndex}`}>
							<LiveCategoryCard.Banner>
								<img
									className="size-[186px] cursor-pointer"
									src={liveCategory.src}
									alt={liveCategory.alt}
									onClick={onLiveCategoryCardClicked}
								/>
							</LiveCategoryCard.Banner>
						</LiveCategoryCard>
					))}
				</GameCategory.InlineCardsWrapper>
			</GameCategory>

			<GameCategory>
				<GameCategory.Title icon={<SportsIcon />}>{t('global_sportsbook')}</GameCategory.Title>
				<GameCategory.InlineCardsWrapper>
					{sportCategories.map((sport: SportCategory, index: number) => (
						<SportCategoryCard key={`sport-category-${sport.name}-${index}`}>
							<SportCategoryCard.Banner icon={sport.icon} onClick={onSportsCategoryCardClicked}>
								{sport.name}
							</SportCategoryCard.Banner>
						</SportCategoryCard>
					))}
				</GameCategory.InlineCardsWrapper>
			</GameCategory>
		</>
	);
};
