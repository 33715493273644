import * as React from 'react';

const SvgFootball = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={41} height={40} fill="none" {...props}>
		<path
			fill="#707290"
			d="M20.571 2c-9.924 0-18 8.075-18 18s8.076 18 18 18c9.925 0 18-8.075 18-18s-8.075-18-18-18m1.143 5.848 4.629-2.489a15.8 15.8 0 0 1 6.5 4.793l-1.103 5.09-3.53 1.73-6.496-4.732zm-6.876-2.502 4.629 2.497v4.397l-6.492 4.731-3.548-1.732-1.102-5.121a15.8 15.8 0 0 1 6.513-4.772m-7.25 23.55A15.65 15.65 0 0 1 4.9 21.53l3.81-4.138 3.473 1.699 2.617 7.432-2.212 2.65zm16.995 6.316c-1.285.34-2.623.538-4.012.538-1.678 0-3.294-.27-4.812-.758l-1.416-4.406 2.257-2.711h7.952l2.216 2.624zm3.94-6.115-2.176-2.577L29 19.091l3.458-1.694 3.787 4.135a15.6 15.6 0 0 1-2.126 6.46z"
		/>
	</svg>
);

export default SvgFootball;
