import * as React from 'react';

type LiveCategoryCardProps = {
	children: React.ReactNode;
};

interface LiveCategoryCardNamespace {
	Banner: React.FC<LiveCategoryCardProps>;
}

const LiveCategoryCard: React.FC<LiveCategoryCardProps> & LiveCategoryCardNamespace = ({ children }) => {
	return <>{children}</>;
};

LiveCategoryCard.Banner = ({ children }: LiveCategoryCardProps) => {
	return <>{children}</>;
};

export default LiveCategoryCard;
