import * as React from 'react';

type GameProviderCardProps = {
	children: React.ReactNode;
};

interface GameProviderCardNamespace {
	Banner: React.FC<GameProviderCardProps>;
}

const GameProviderCard: React.FC<GameProviderCardProps> & GameProviderCardNamespace = ({ children }) => {
	return <>{children}</>;
};

GameProviderCard.Banner = ({ children }: GameProviderCardProps) => {
	return <>{children}</>;
};

export default GameProviderCard;
